import { render, staticRenderFns } from "./EditoAppDownloadNotice.vue?vue&type=template&id=2e199e7b"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLink: require('/home/runner/work/webapp/webapp/components/atoms/link/BaseLink.vue').default})
