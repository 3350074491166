
import { ValueOf } from 'type-fest';
import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';

import { ELabelledById } from '@/domain/core/a11y/LabelledById.enum';
import { ESidebarComponentId } from '@/domain/core/a11y/SidebarComponentId.enum';
import { ECookie } from '@/domain/core/http/Cookie.enum';
import { EMaxAge } from '@/domain/core/http/MaxAge.enum';
import { EHttpStatusCode } from '@/domain/core/http/StatusCode.enum';
import { HttpResponse } from '@/infrastructure/core/http/HttpResponse.interface';
import { EGtmAuthenticationMethod, EGtmEventUser } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

type IdentityProvider = ValueOf<typeof EGtmAuthenticationMethod>;

export default Vue.extend({
  name: 'FormLogin',
  props: {
    currentEmail: {
      type: String as PropType<string>,
      default: '',
    },
    targetPath: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  data() {
    return {
      EGtmAuthenticationMethod: Object.freeze(EGtmAuthenticationMethod),
      ELabelledById: Object.freeze(ELabelledById),
      form: {
        email: this.currentEmail,
        password: '',
      },
      hasSentTooManyRequests: false,
      hasInvalidPassword: false,
      isLoading: false,
      userKnownEmail: null as string | null,
    };
  },
  computed: {
    emailErrorLabel(): TranslateResult | null {
      return this.hasSentTooManyRequests ? this.$t('form.common.errors.tooManyRequests') : null;
    },
    isUserAlreadyRegistered(): boolean {
      return this.form.email === this.userKnownEmail;
    },
    passwordErrorLabel(): TranslateResult | null {
      return this.hasInvalidPassword ? this.$t('form.common.errors.password.invalid') : null;
    },
    submitButtonlabel(): TranslateResult {
      let label = this.$t('form.common.button.continue');

      if (this.isUserAlreadyRegistered) {
        label = this.$t('auth.login.default');
      }

      return label;
    },
    title(): TranslateResult {
      let label = this.$t('auth.login.generic');

      if (!this.isUserAlreadyRegistered) {
        label += ` / ${this.$t('auth.register.generic')}`;
      }

      return label;
    },
  },
  methods: {
    async handleSubmit(): Promise<void> {
      if (this.isUserAlreadyRegistered) {
        await this.login();
      } else {
        await this.verifyUserExistence();
      }
    },
    async verifyUserExistence(): Promise<void> {
      try {
        this.hasSentTooManyRequests = false;
        this.setLoadingState(true);

        await this.$repositories.user.getPublicDetailsByEmail(this.form.email);

        this.userKnownEmail = this.form.email;
      } catch (err) {
        const { statusCode } = err as HttpResponse;

        if (statusCode === EHttpStatusCode.NotFound) {
          this.openFormRegisterSidebar();
        } else if (statusCode === EHttpStatusCode.TooManyRequests) {
          this.hasSentTooManyRequests = true;
        } else {
          this.$errorMonitor.report(err, 'fatal');
        }
      } finally {
        this.setLoadingState(false);
      }
    },
    handleLoginSuccess(method: IdentityProvider): void {
      this.handleTracking({ method });

      this.redirectOrReload();
    },
    async handleRegisterSuccess(method: IdentityProvider): Promise<void> {
      this.handleTracking({ isNewUser: true, method });

      try {
        await this.$accessor.user.fetchUser();

        this.$accessor.ui.openSidebar({
          currentComponent: {
            component: () => import('@/components/organisms/form/FormRegisterAfterOneClick.vue'),
            id: ESidebarComponentId.FormRegisterAfterOneClick,
            props: {
              targetPath: this.targetPath,
            },
          },
          previousComponent: null,
        });
      } catch (err) {
        this.$errorMonitor.report(err, 'fatal');
      }
    },
    handleTracking({ isNewUser, method }: { isNewUser?: boolean; method: IdentityProvider }): void {
      this.$gtm.push({
        event: isNewUser ? EGtmEventUser.Register : EGtmEventUser.Login,
        method,
      });
    },
    async fetchAndSetCartIdCookie(): Promise<void> {
      try {
        if (this.$cookies.get(ECookie.UserCartId) !== undefined) {
          return;
        }

        await this.$accessor.user.fetchUser(); // NOTE: needed to populate the auth token within the store.
        const cart = await this.$repositories.cart.getByAuthToken();

        if (cart?.id) {
          this.$cookies.set(ECookie.UserCartId, cart.id, {
            maxAge: EMaxAge.TenDays,
            path: '/',
            sameSite: 'lax',
          });
        }
      } catch (err) {
        this.$errorMonitor.report(err, 'info');
      }
    },
    async login(): Promise<void> {
      try {
        this.setLoadingState(true);
        this.hasInvalidPassword = false;
        this.hasSentTooManyRequests = false;

        await this.$services.authService.login({
          username: this.form.email,
          password: this.form.password,
        });

        await this.fetchAndSetCartIdCookie();

        this.handleTracking({ method: EGtmAuthenticationMethod.Email });
        this.redirectOrReload(); // TODO: fetch user's informations instead of reloading.
      } catch (err) {
        const { statusCode } = err as HttpResponse;

        if (statusCode === EHttpStatusCode.Unauthorized) {
          this.hasInvalidPassword = true;
        } else if (statusCode === EHttpStatusCode.TooManyRequests) {
          this.hasSentTooManyRequests = true;
        } else {
          this.$errorMonitor.report(err, 'fatal');
        }

        this.setLoadingState(false); // TODO: invoke this within a finally statement once we stop reloading the page on success.
      }
    },
    openFormRegisterSidebar(): void {
      const props = {
        currentEmail: this.form.email,
        targetPath: this.targetPath,
      };

      this.$accessor.ui.openSidebar({
        currentComponent: {
          component: () => import('@/components/organisms/form/FormRegister.vue'),
          id: ESidebarComponentId.FormRegister,
          props,
        },
        previousComponent: {
          component: () => import('@/components/organisms/form/FormLogin.vue'),
          id: ESidebarComponentId.FormLogin,
          props,
        },
      });
    },
    redirectOrReload(): void {
      if (this.targetPath) {
        window.location.href = this.targetPath;
      } else {
        window.location.reload();
      }
    },
    setLoadingState(state: boolean): void {
      this.isLoading = state;
    },
  },
});
