
import Vue from 'vue';

import IcAppleRounded from '@/assets/svg/ic_apple_rounded.svg?inline';
import { LoginApplePayload } from '@/domain/auth/types';
import { AppleApiSignInResponse } from '@/infrastructure/auth/types';
import { _insertScript } from '@/utilities/DOM/insertScript';

export default Vue.extend({
  name: 'ButtonConnectApple',
  components: {
    IcAppleRounded,
  },
  data() {
    return {
      isFetchingSDK: false,
      isLoading: false,
    };
  },
  mounted() {
    this.loadAppleSDK();
  },
  methods: {
    async loadAppleSDK(): Promise<void> {
      try {
        this.setSDKFetchingState(true);

        await _insertScript({
          id: 'apple-jssdk',
          src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
        });
      } catch (err) {
        this.$errorMonitor.report(err, 'fatal');
      } finally {
        this.setSDKFetchingState(false);
      }
    },
    async initAppleSDK(): Promise<void> {
      try {
        await window.AppleID.auth.init({
          clientId: this.$config.apple.app.id,
          scope: 'name email',
          redirectURI: window.location.origin,
          usePopup: true,
        });
      } catch (err) {
        this.$errorMonitor.report(err, 'error');
      }
    },
    async signInApple(): Promise<AppleApiSignInResponse | null> {
      let appleResponse = null;

      try {
        appleResponse = await window.AppleID.auth.signIn();

        if ('error' in appleResponse) {
          throw new Error(appleResponse.error);
        }
      } catch (err) {
        appleResponse = null;

        this.$errorMonitor.report(err, 'warning');
      }

      return appleResponse;
    },
    async loginSelency(appleResponse: AppleApiSignInResponse): Promise<void> {
      const appleUser = appleResponse.user;

      let payload: LoginApplePayload = {
        token: appleResponse.authorization?.id_token,
      };

      if (appleUser) { // appleUser is only available on subscription. Only identityToken is needed on login.
        payload = {
          ...payload,
          email: appleUser.email,
          firstName: appleUser.name?.firstName,
          lastName: appleUser.name?.lastName,
        };
      }

      try {
        this.setLoadingState(true);

        const { isNewUser } = await this.$services.authService.loginWithApple(payload);
        const eventName = isNewUser ? 'register' : 'login';

        this.$emit(eventName);
      } catch (err) {
        this.$emit('error', err);
        this.$errorMonitor.report(err, 'fatal');
      } finally {
        this.setLoadingState(false);
      }
    },
    async logIn(): Promise<void> {
      await this.initAppleSDK();
      const appleResponse = await this.signInApple();
      const isSuccessfullyLoggedIn = appleResponse?.authorization;

      if (isSuccessfullyLoggedIn) {
        await this.loginSelency(appleResponse as AppleApiSignInResponse);
      }
    },
    setLoadingState(state: boolean): void {
      this.isLoading = state;
    },
    setSDKFetchingState(state: boolean): void {
      this.isFetchingSDK = state;
    },
  },
});
