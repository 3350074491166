
import Vue from 'vue';
import type { MetaInfo } from 'vue-meta';

import { ERouteName } from '@/domain/core/Routes.enum';
import { createMetaTags } from '@/head/meta/tags';
import { _setCacheControlHeader } from '@/utilities/http/setCacheControlHeader';

export default Vue.extend({
  name: 'LoginPage',
  middleware: _setCacheControlHeader(),
  asyncData({ $accessor, redirect, route }): { targetPath: string | null } {
    const targetPath = route?.query?._target_path as string || null;

    if ($accessor.user.authenticated) {
      if (targetPath) {
        redirect(targetPath);
      } else {
        redirect({ name: ERouteName.Homepage });
      }
    }

    return {
      targetPath: route?.query?._target_path as string || null,
    };
  },
  data() {
    return {
      targetPath: null,
    };
  },
  head(): MetaInfo {
    return {
      title: this.title,
      meta: createMetaTags({
        description: this.$t('meta.default.description') as string,
        title: this.title,
      }),
    };
  },
  computed: {
    title(): string {
      return `${this.$t('auth.login.generic')} / ${this.$t('auth.register.generic')} | Selency`;
    },
  },
});
