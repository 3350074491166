import { Context } from '@nuxt/types';

import { CacheControl, ECacheControl } from '@/domain/core/http/CacheControl';
import { EHttpHeader } from '@/domain/core/http/Header';
import { EMaxAge } from '@/domain/core/http/MaxAge.enum';

const getCacheControlPolicyValues = (values: CacheControl): string => Object.entries(values)
  .map(([key, value]) => {
    let result = `${key}=${value}`;

    if (value === true) { // NOTE: we don't want to verify the truthiness here.
      result = key;
    }

    return result;
  })
  .join(', ');

// NOTE: this is a utility function used as an anonymous middleware to allow passing values, not a named middleware.
// (See : https://v2.nuxt.com/docs/directory-structure/middleware/#named-middleware)
// WARNING: usable server-side only.
export const _setCacheControlHeader = (customPolicy: CacheControl | null = null, isPublic = false) => ({ res }: Context): void => {
  if (process.client) {
    return;
  }

  const privatePolicy: CacheControl = {
    [ECacheControl.MustRevalidate]: true,
    [ECacheControl.Private]: true,
  };
  const publicPolicy: CacheControl = {
    [ECacheControl.SMaxAge]: EMaxAge.FifteenMinutes,
    [ECacheControl.Public]: true,
  };

  let currentPolicy = isPublic ? publicPolicy : privatePolicy;

  if (customPolicy) {
    currentPolicy = customPolicy;
  }

  res?.setHeader(EHttpHeader.CacheControl, getCacheControlPolicyValues(currentPolicy));
};
